<template>
    <div class="contentGrid">
        
        <p>Driver's account enables driver to use Driver Dashboard. Driver Dashboard provides access to driver's loads and invoices.</p>

        <div v-if="item.username" class="driverAccess">
            <div>Driver has an access already</div>
        </div>

        <div class="driverAccess" v-else>
            <p>This driver isn't registered yet. You can send invitation to email address or phone number.</p>
            <div class="rowOfTwo">
                <button class="default medium reverse" :disabled="item.email ? false : true" @click="inviteVia('email')">Invite via Email</button>
                <button class="default medium reverse" :disabled="item.phone ? false : true" @click="inviteVia('sms')">Invite via SMS</button>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: ['item', 'errors'],
        data() {
            return {

            }
        },
        methods: {
            inviteVia(type) {
                this.ajax('inviteDriver', {
                    url: `/myDrivers/invite/${this.item._id}/${type}`,
                    method: 'POST'
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    alert("Invitation has been sent.");
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 40px;
}

p {
    font-size: 12px;
}

.driverAccess {
    display: grid;
    row-gap: 20px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.rowOfTwo {
    display: grid;
    column-gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}
</style>