<template>
    <div class="contentGrid">

        <Input name="Street 1" placeholder="Street 1" :error="errors.street1" v-model="item.street1"/>
        <Input name="Street 2" placeholder="Street 2" :error="errors.street2" v-model="item.street2"/>
        <Input name="City" placeholder="City" :error="errors.city" v-model="item.city"/>

        <div class="rowOfTwo">
            <div class="input">
                <div class="_header">
                    <label>State</label>
                    <span class="_error">{{ errors.state }}</span>
                </div>
                <div class="inputHolder">
                    <select v-model="item.state">
                        <option>-- State --</option>
                        <option :key="key" :value="state" v-for="(state, key) of states">{{ state }}</option>
                    </select>
                </div>
            </div>

            <Input name="ZIP" placeholder="ZIP" :error="errors.zip" v-model="item.zip"/>
        </div>


    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['item', 'errors'],
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['states'])
        },
        methods: {
            
        },
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 10px;
}


.rowOfTwo {
    display: grid;
    column-gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}
</style>