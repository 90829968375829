<template>
    <div class="driver">
        <div class="name">{{ driver.name }} {{ driver.lastname }}</div>
        <div class="phone">{{ driver.phone }}</div>
        <div class="truck">{{ truckDetails }}</div>
        <div class="actionBtns">
            <button @click="$emit('edit')"><i class="fa-solid fa-pencil"></i></button>
            <button @click="$emit('remove')" class="remove"><i class="fa-solid fa-trash-can"></i></button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['driver'],
        computed: {
            truckDetails() {
                if(!this.driver.truck) return 'No Truck Assigned';
                if(!this.driver.truck[0]) return 'No Truck Assigned';

                return this.driver.truck[0].name;
            }
        }
    }
</script>

<style lang="scss" scoped>
.driver {
    background: $bgSecondary;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    display: grid;
    column-gap: 10px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 55px;
    &:hover {
        z-index: 1;
        box-shadow: $boxShadow;
    }
    .name {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .details {
        color: $subtle;
    }
    .truckDetails {
        text-transform: uppercase;
    }
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}

</style>