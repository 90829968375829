<template>
    <div>
        <div class="driversContent">
            

            <div class="driversList">
                <div class="title">
                    <h2>My Drivers</h2>
                    <button class="default medium" @click="newDriver.modal = true">Add driver</button>
                </div>
                <div class="list" v-if="drivers.length > 0">
                    <div class="item header">
                        <div class="col">Name</div>
                        <div class="col">Phone</div>
                        <div class="col">Truck #</div>
                        <div class="col"></div>
                    </div>
                    <DriverItem class="item" @edit="editDriver(driver)" @remove="deleteDriver(driver._id)" :key="driver._id" v-for="driver of drivers" :driver="driver"/>
                </div>
                <div class="noDriversMessage" v-else>
                    <div class="icon"><i class="fa-regular fa-id-card"></i></div>
                    <div class="message">You haven't added any drivers yet.</div>
                </div>
            </div>

        </div>

        <ModalSimple :loading="isLoading(['inviteDriver', 'saveDriverDetails'])" v-model="newDriver.modal" :title="newDriver.item._id ? 'Edit Driver' : 'Add Driver'" @confirm="handleSaveDriversInfo" confirm="Save driver" @close="closeAddModal">
            <Save :item="newDriver.item" :errors="newDriver.errors" v-if="newDriver.modal"/>
        </ModalSimple>

    </div>
</template>

<script>
import DriverItem from '../components/DriverItem.vue'
import Save from '../components/Drivers/Save.vue'

    export default {
        components: {
            DriverItem,
            Save
        },
        data() {
            return {
                drivers: [],
                newDriver: {
                    modal: false,
                    item: {},
                    errors: {}
                }
            }
        },
        methods: {
            closeAddModal() {
                this.newDriver.item = {};
                this.newDriver.errors = {};
            },
            editDriver(driver) {
                this.newDriver.item = JSON.parse(JSON.stringify(driver));
                this.newDriver.modal = true;
            },
            getDrivers() {
                this.ajax('getListOfDrivers', {
                    url: '/myDrivers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            },
            deleteDriver(id) {

                let confirmDelete = confirm("Do you really want to delete this driver?");
                if(!confirmDelete) return;

                this.ajax('deleteDriver', {
                    url: `/myDrivers/${id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.getDrivers();
                });
            },
            handleSaveDriversInfo() {
                this.newDriver.errors = {};
                this.ajax('saveDriverDetails', {
                    url: '/myDrivers',
                    method: 'POST',
                    data: this.newDriver.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.newDriver.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    if(!this.newDriver.item._id) {
                        this.newDriver.modal = false;
                        this.newDriver.item = {};
                    }
                    
                    this.getDrivers();
                });
                
            }
        },
        mounted() {
            this.getDrivers();
        }
    }
</script>

<style lang="scss" scoped>

.driversList {
    display: grid;
    row-gap: 20px;
}


.list {
    overflow-x: auto;
    border-radius: $borderRadius;
    .item {
        min-width: 1060px;
    }
    .header {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 55px;
        background: $bgSecondary;
        padding: 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        column-gap: 10px;
        font-size: 12px;
        .col {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.noDriversMessage {
    padding: 100px 30px;
    display: grid;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    background: $bgSecondary;
    border-radius: $borderRadius;
    .icon {
        font-size: 50px;
    }
    .message {
        font-size: 14px;
        opacity: 0.8;
    }
}

.driversContent {
    padding: 30px 0;
}

.newDriverContent {
    display: grid;
    row-gap: 20px;
}

.rowOfTwo {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 20px;
}
</style>