<template>
    <div class="contentGrid">

        <Input name="CDL #" placeholder="CDL #" :error="errors.cdlNumber" v-model="item.cdlNumber"/>

        <div class="input">
                <div class="_header">
                    <label>CDL State</label>
                    <span class="_error">{{ errors.cdlState }}</span>
                </div>
                <div class="inputHolder">
                    <select v-model="item.cdlState">
                        <option>-- State --</option>
                        <option :key="key" :value="state" v-for="(state, key) of states">{{ state }}</option>
                    </select>
                </div>
            </div>

        <div class="input">
            <div class="_header">
                <label>CDL Expiration Date</label>
                <span class="_error">{{ errors.cdlExpiration }}</span>
            </div>
            <div class="dateHolder" :class="{ 'active' : show.has('cdlExpiration') }">
                <div class="date"><a href="" @click.prevent="switchShow('cdlExpiration')">{{ dateToString(item.cdlExpiration) }}</a></div>
                <div class="clear"><a href="" @click.prevent="item.cdlExpiration = null">Clear</a></div>
                <DatePickerSimple class="calendar" v-click-outside="() => { hideShow('cdlExpiration') }" v-model="item.cdlExpiration" @select="hideShow('cdlExpiration')" v-if="show.has('cdlExpiration')"/>
            </div>
        </div>

    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['item', 'errors'],
        data() {
            return {
                show: new Set()
            }
        },
        computed: {
            ...mapGetters(['states'])
        },
        methods: {
            dateToString(date) {
                if(!date) return 'Not selected';
                let mDate = moment.utc(date);
                if(!mDate.isValid()) return 'Not selected';
                return mDate.format("MM/DD/YYYY");
            },
            switchShow(item) {
                if(this.show.has(item)) {
                    this.show.delete(item);
                    return;
                }
                this.show.add(item);
            },
            hideShow(item) {
                this.show.delete(item);
            }
        },
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 10px;
}


.dateHolder {
    position: relative;
    padding: 0 10px;
    height: 40px;
    border: 1px solid $borderColor;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: $bgSecondary;
    border-radius: $borderRadius;
    font-size: 13px;
    &.active  {
        z-index: 2;
    }
    .calendar {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translate(0, 20px);
    }
}
</style>