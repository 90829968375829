<template>
    <div class="contentGrid">

        <Input name="First name" placeholder="First name" :error="errors.name" v-model="item.name"/>
        <Input name="Middle name" placeholder="Middle name" :error="errors.middlename" v-model="item.middlename"/>
        <Input name="Last name" placeholder="Last name" :error="errors.name" v-model="item.lastname"/>


        <div class="input">
            <div class="_header">
                <label>Date of birth</label>
                <span class="_error">{{ errors.dob }}</span>
            </div>
            <div class="dateHolder" :class="{ 'active' : show.has('dob') }">
                <div class="date"><a href="" @click.prevent="switchShow('dob')">{{ dateToString(item.dob) }}</a></div>
                <div class="clear"><a href="" @click.prevent="item.dob = null">Clear</a></div>
                <DatePickerSimple class="calendar" v-click-outside="() => { hideShow('dob') }" v-model="item.dob" @select="hideShow('dob')" v-if="show.has('dob')"/>
            </div>
        </div>

    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'
import moment from 'moment'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['item', 'errors'],
        data() {
            return {
                show: new Set()
            }
        },
        methods: {
            dateToString(date) {
                if(!date) return 'Not selected';
                let mDate = moment.utc(date);
                if(!mDate.isValid()) return 'Not selected';
                return mDate.format("MM/DD/YYYY");
            },
            switchShow(item) {
                if(this.show.has(item)) {
                    this.show.delete(item);
                    return;
                }
                this.show.add(item);
            },
            hideShow(item) {
                this.show.delete(item);
            }
        },
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 10px;
}


.dateHolder {
    position: relative;
    padding: 0 10px;
    height: 40px;
    border: 1px solid $borderColor;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: $bgSecondary;
    border-radius: $borderRadius;
    font-size: 13px;
    &.active  {
        z-index: 2;
    }
    .calendar {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translate(0, 20px);
    }
}
</style>