<template>
    <div class="contentGrid">

        <div class="headerHolder">
            <div class="tabs">
                <div class="tab" :class="{ 'active' : selectedTab == key }" @click="selectedTab = key" :key="key" v-for="(tab, key) of tabs">
                    <div class="name">{{ tab.name }}</div>
                    <span class="count" v-if="tab.count > 0">{{ tab.count }}</span>
                </div>
            </div>
        </div>

        <div class="componentHolder">
            <component :is="tabs[selectedTab].component" :item="item" :errors="errors"></component>
        </div>

    </div>
</template>

<script>
import { shallowRef } from '@vue/reactivity'
import General from './General.vue'
import Address from './Address.vue'
import Contact from './Contact.vue'
import CDLInfo from './CDLInfo.vue'
import AppAccess from './AppAccess.vue'


    export default {
        components: {
            General,
            Address,
            Contact,
            CDLInfo,
            AppAccess
        },
        props: ['item', 'errors'],
        data() {
            return {
                selectedTab: 0
            }
        },
        computed: {
            tabs() {
                let array = [
                    { name: 'Personal Info', component: General },
                    { name: 'Address', component: Address },
                    { name: 'Contact', component: Contact },
                    { name: 'CDL Info', component: CDLInfo }
                ]

                if(this.item._id) {
                    array.push({ name: 'App Access', component: AppAccess });
                }

                return array;
            }
        }
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 20px;
}

.headerHolder {
    border-bottom: 1px solid $borderColor;
    .tabs {
        display: flex;
        flex-wrap: wrap;
        .tab {
            padding: 0 10px;
            display: flex;
            height: 40px;
            align-items: center;
            cursor: pointer;
            // border-radius: 5px 5px 0 0;
            transition: ease 0.3s;
            user-select: none;
            .count {
                padding: 5px 7px;
                background: $buttonColor2;
                border-radius: 5px;
                font-size: 12px;
                font-weight: bold;
                transition: ease 0.3s;
                margin-left: 15px;
            }
            .name {
                font-size: 12px;
                transition: ease 0.3s;
                font-weight: bold;
            }
            &.active {
                background: $buttonColor2;
                color: $buttonColor;
                .count {
                    color: $buttonText;
                    background: $buttonColor;
                }
            }
        }
    }
}
</style>