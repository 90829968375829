<template>
    <div class="contentGrid">
        <Input name="Cell Phone #" placeholder="Cell Phone #" :error="errors.phone" v-model="item.phone"/>
        <Input name="Email address" placeholder="Email address" :error="errors.email" v-model="item.email"/>

    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['item', 'errors'],
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['states'])
        },
        methods: {
            
        },
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 10px;
}


.rowOfTwo {
    display: grid;
    column-gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}
</style>