<template>
    <div class="datePickerGroup">
        
        <div class="datePicker">

            <div class="datePicker yearAndMonthPicker" v-if="selectYearAndMonth && selectYearAndMonth === 1">
                <div class="header">
                    <div class="currentMonthYearHolder">
                        <a href=""  @click.prevent="selectYearAndMonth = null" class="currentMonthYear">{{ date.format("MMMM") }} {{ date.format("YYYY") }}</a>
                    </div>
                    <button @click="prevYearPage"><i class="fas fa-caret-left"></i></button>
                    <button @click="nextYearPage"><i class="fas fa-caret-right"></i></button>
                </div> 
                <div class="content listOfYears">
                    <button :key="year" v-for="year of listOfYears" :class="{ 'selected' : year == date.format('YYYY') }" @click="selectYear(year)">{{ year }}</button>
                </div>
            </div>

            <div class="datePicker yearAndMonthPicker" v-if="selectYearAndMonth && selectYearAndMonth === 2">
                <div class="header">
                    <div class="currentMonthYearHolder">
                        <a href="" @click.prevent="selectYearAndMonth = null" class="currentMonthYear">{{ date.format("MMMM") }} {{ date.format("YYYY") }}</a>
                    </div>
                </div> 
                <div class="content listOfMonths">
                    <button :key="key" v-for="(month, key) of months" :class="{ 'selected' : key == date.month() }" @click="selectMonth(key)">{{ month }}</button>
                </div>
            </div>


            <div class="header">
                <div class="currentMonthYearHolder">
                    <a href="" @click.prevent="selectYearAndMonth = 1" class="currentMonthYear">{{ date.format("MMMM") }} {{ date.format("YYYY") }}</a>
                </div>
                <button @click="prevMonth"><i class="fas fa-caret-left"></i></button>
                <button @click="nextMonth"><i class="fas fa-caret-right"></i></button>
            </div> 

            <div class="daysPicker">
                <div class="daysGroup">
                    <div class="day name" :key="key" v-for="(item, key) of days">
                        <div class="buttonHolder">
                            <button class="_btn" >{{ item }}</button>
                        </div>
                    </div>
                </div>

                <div class="daysGroup">
                    <div class="day" :key="key" v-for="(item, key) of monthDays">
                        <div class="buttonHolder">
                            <button class="_btn" @click="selectDate(item.date); $emit('select');" :class="{ 'active' : item.date && item.date.getTime() == selectedDate, 'current' : item.date && item.date.getTime() === todaysDate.getTime(), 'subtle' : item.subtle, 'disabled' : item.disabled }">{{ item.day }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['modelValue', 'position', 'from'],
        data() {
            return {
                date: moment.utc().startOf('month'),
                todaysDate: moment.utc().startOf('day').toDate(),
                selected: null,
                days: ['','Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                yearSelection: {
                    page: 0
                },
                selectYearAndMonth: null
            }
        },
        mounted() {
            this.validateMounted();
        },
        methods: {
            selectMonth(month) {
                this.date = this.date.clone().month(month);
                this.selectYearAndMonth = null;
            },
            selectYear(year) {
                this.date = this.date.clone().year(year);
                this.yearSelection.page = 0;
                this.selectYearAndMonth = 2;
            },
            prevYearPage() {
                this.yearSelection.page--;
            },
            nextYearPage() {
                this.yearSelection.page++;
            },
            validateMounted() {
                if(this.modelValue) {
                    let date = moment.utc(this.modelValue);
                    if(date.isValid()) {
                        if(this.selected && this.selected.toDate().getTime() === date.toDate().getTime()) return;
                        this.selectDate(this.modelValue);
                    }
                }
            },
            selectDate(date) {
                this.selected = moment.utc(date).startOf('day');
                this.date = this.selected.clone().startOf('month');
            },
            prevMonth() {
               this.date = this.date.clone().subtract(1, 'months');
            },
            nextMonth() {
                this.date = this.date.clone().add(1, 'months');
            }
        },
        computed: {
            listOfYears() {
                let array = [];
                let year = this.date.year();
                year -= 7 - (this.yearSelection.page * 15);

                for(let i = 0; i < 15; i++) {
                    array.push(year + i);
                }

                return array;
            },
            selectedDate() {
                if(!this.selected) return 'NOT_SELECTED';
                return this.selected.toDate().getTime();
            },
            monthDays() {
                let date = this.date;

                let numberOfDays = date.daysInMonth();
                let days = [];

                for(let i = 1; i <= numberOfDays; i++) {
                    date.date(i);
                    days.push({
                        day: i,
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.toDate()
                    });
                }

                // get the first day
                let firstDay = days[0];
                date = moment.utc(firstDay.date);

                for(let i = 1; i < firstDay.weekDay; i++) {
                    date.subtract(1, 'days');
                    days.unshift({
                        day: date.date(),
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.toDate(),
                        subtle: true
                    });
                }

                // get the last day
                let lastDay = days[days.length - 1];
                date = moment.utc(lastDay.date, "MM/DD/YYYY");

                for(let i = lastDay.weekDay; i < 7; i++) {
                    date.add(1, 'days');
                    days.push({
                        day: date.date(),
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.toDate(),
                        subtle: true
                    });
                }

                for(let i = 0; i < days.length; i++) {
                    if(days[i].weekDay == 1) {
                        days.splice(i, 0, {
                            day: "W" + days[i].weekNum,
                            disabled: true
                        });
                        i += 2;
                    }
                }

                return days;
            }
        },
        watch: {
            selected() {
                this.$emit('update:modelValue', this.selected);
            },
            modelValue() {
                this.validateMounted();
            }
        }
    }
</script>

<style lang="scss" scoped>
.datePickerGroup {
   
}


.datePicker {
    width: 100%;
    width: 340px;
    z-index: 9999;
    overflow: hidden;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    position: relative;
}



.listOfYears, .listOfMonths {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
    &.listOfMonths {
        grid-template-rows: repeat(4, minmax(0, 1fr));
    }
    button {
        border: 0;
        background: transparent;
        border-radius: 5px;
        &:hover {
            background: $buttonColor2;
            border: 1px solid $borderColor;
        }
        &.selected {
            background: $buttonColor;
            color: $buttonText;
            font-weight: bold;
        }
    }
}


.yearAndMonthPicker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
}

.daysPicker {
    padding: 10px;
}

.header {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 60px  60px;
    height: 60px;
    border-bottom: 1px solid $borderColor;
    .currentMonthYearHolder {
        display: flex;
        align-items: center;
        padding: 0 20px;
        .currentMonthYear {
            font-size: 16px;
            font-weight: 600;
        }
    }
    button {
        border: 0;
        background: transparent;
    }
}

.daysGroup {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 5px;
    .day {
        text-align: center;
        .buttonHolder {
            width: 100%;
            padding-top: 100%;
            position: relative;
        }
        ._btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            background: transparent;
            border-radius: 50%;
            padding: 0;
            &.subtle {
                color: #ccc;
            }
            &:hover {
                border: 1px solid #ccc;
            }
            &.active {
                color: $buttonText !important;
                background: $buttonColor;
                font-weight: bold;
            }
            &.current {
                color: $buttonColor;
                border: 1px solid $buttonColor;
                font-weight: bold;
            }
            &.disabled {
                background: $buttonColor2;
                font-size: 10px;
                cursor: auto;
                color: $buttonColor;
                &:hover {
                    border: 0;
                }
            }
            &.noSelect {
                color: #D8000C;
                background-color: #FFBABA;
                cursor: not-allowed;
            }
        }
        &.name {
            ._btn {
                cursor: default;
                font-size: 10px;
                &:hover {
                    border: 0;
                }
            }
        }
    }
}

</style>